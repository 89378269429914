import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SplashScreen } from './components/SplashScreen/SplashScreen';
import { Menu } from './components/Menu/Menu';
import { getCurrentLanguage } from './shared/providers/languageProvider';
import './App.css';
import { transaltionResources } from './locales/resources';
import { getCookieConsent } from './shared/providers/cookieConsentProvider';
import { CookieSetting } from './types/cookieSetting';

i18n.use(initReactI18next).init({
    resources: transaltionResources,
    lng: getCurrentLanguage().id,
    fallbackLng: 'sk',
    interpolation: {
        escapeValue: false,
    },
});

const areAnalyticsEnabled = getCookieConsent() === CookieSetting.Allowed;

if (areAnalyticsEnabled) {
    const TRACKING_ID = 'G-K2WGN0NHB4';
    ReactGA.initialize(TRACKING_ID);
}

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SplashScreen />} />
                <Route path="/:city/menu" element={<Menu />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
