import React from 'react';
import { CityType } from '../../../types/cityType';
import facebookIcon from '../../../images/fb.png';
import instagramIcon from '../../../images/instagram.png';
import { t } from 'i18next';
import './NavigationBar.scss';

interface NavigationBarProp {
    city: CityType;
}

export const NavigationBar = (prop: NavigationBarProp) => {
    return (
        <div className="navigation-bar">
            <div className="container navigation-bar__container">
                <div className="navbar-left navbar__links">
                    <div className="link">
                        <span className="navbar-icon material-symbols-outlined">
                            local_pizza
                        </span>
                        <a href="#FoodList" className="navbar-text">
                            {t('Ponuka')}
                        </a>
                    </div>
                    <div className="link">
                        <span className="navbar-icon material-symbols-outlined">
                            imagesmode
                        </span>
                        <span className="navbar-text">{t('Galeria')}</span>
                    </div>
                    <div className="link">
                        <span className="navbar-icon material-symbols-outlined">
                            contact_support
                        </span>
                        <a href="#Contacts" className="navbar-text">
                            {t('Kontakty')}
                        </a>
                    </div>
                </div>
                <div className="navbar__social">
                    <img
                        alt="instagram"
                        src={instagramIcon}
                        className="instagram-logo"
                    />
                    <a href="https://www.facebook.com/people/DrPizza/100063922936622/">
                        <img
                            alt="fb-logo"
                            src={facebookIcon}
                            className="fb-logo"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};
