import React from 'react';
import { t } from 'i18next';
import { CityType } from '../../../types/cityType';
import './Banner.scss';

type BannerParameters = {
    city: CityType;
};

export const Banner = (params: BannerParameters) => {
    return (
        <div className="banner">
            <div className="banner-container container">
                <div className="banner-title">{t('Motto')}</div>
                <div className="banner-title">{t('Motto2')}</div>
            </div>
        </div>
    );
};
