import { getFromLocalStorage, saveToLocalStorage } from '../utils/localStorageUtils';
import { COOKIE_CONSENT } from '../../constants/localStorageKeys';
import { CookieSetting } from '../../types/cookieSetting';

export const saveCookieConsent = (areCookiesAllowed: boolean): void => {
    saveToLocalStorage(COOKIE_CONSENT, areCookiesAllowed.toString());
};

export const getCookieConsent = (): CookieSetting => {
    const value = getFromLocalStorage(COOKIE_CONSENT);
    let setting: CookieSetting = CookieSetting.NotDefined;

    if (value) {
        setting = value === 'true' ? CookieSetting.Allowed : CookieSetting.Rejected;
    }

    return setting;
};