import { t } from 'i18next';
import { CityType } from '../../types/cityType';
import { RestaurantLocation } from '../../types/restaurantLocation';

export const getLocation = (city: CityType): RestaurantLocation => {
    const result = {} as RestaurantLocation;

    switch (city) {
        case CityType.Kolarovo:
            {
                result.id = CityType.Kolarovo;
                result.translate = t('Kolarovo');
            }
            break;
        case CityType.Komarno:
            {
                result.id = CityType.Komarno;
                result.translate = t('Komarno');
            }
            break;
        default:
            {
                result.id = CityType.NoveZamky;
                result.translate = t('NoveZamky');
            }
            break;
    }

    return result;
};
