import React from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../shared/Header/Header';
import { NavigationBar } from '../shared/NavigationBar/NavigationBar';
import { Banner } from '../shared/Banner/Banner';
import { Contacts } from '../shared/Contacts/Contacts';
import { FoodList } from './components/FoodList';
import { CookieConsent } from '../shared/CookieConsent/CookieConsent';

export const Menu = () => {
    const { city }: any = useParams();

    return (
        <div>
            <Header city={city} />
            <NavigationBar city={city} />
            <Banner city={city} />
            <FoodList city={city} />
            <Contacts city={city} />
            <CookieConsent />
        </div>
    );
};
