import { CityType } from '../../types/cityType';

export const getPhoneNumber = (city: CityType): string => {
    switch(city) {
        case CityType.Kolarovo: {
            return '+421 948 422 100';
        }
        case CityType.NoveZamky: {
            return '+421 948 420 120';
        }
        case CityType.Komarno: {
            return '+421 908 101 018';
        }
        default: {
            return '';
        }
    }
};
