import { t } from 'i18next';
import { HU, SK } from '../../constants/languages';
import { LANGUAGE } from '../../constants/localStorageKeys';
import { Language } from '../../types/language';
import {
    getFromLocalStorage,
    saveToLocalStorage,
} from '../utils/localStorageUtils';

export const getLanguage = (lang: string): Language =>
    lang === HU
        ? { id: HU, translate: t('Hungarian') }
        : { id: SK, translate: t('Slovak') };

export const getCurrentLanguage = (): Language => {
    let language = getFromLocalStorage(LANGUAGE);

    if (!language) {
        language = SK;
    }

    return getLanguage(language);
};

export const setLanguage = (lang: string): void =>
    saveToLocalStorage(LANGUAGE, lang);
