import React from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { CityType } from '../../types/cityType';
import logo from '../../images/splash-header.png';
import './SplashScreen.scss';
import { CookieConsent } from '../shared/CookieConsent/CookieConsent';

export const SplashScreen = () => (
    <div className="splash-screen-container container-fluid">
        <div className="row">
            <div className="splash-screen-content col-md-12 col-lg-6">
                <div className="splash-screen-content_wrapper">
                    <div className="splash-screen-header">
                        <img alt="logo" src={logo} />
                    </div>
                    <div className="splash-screen-city-container">
                        <div className="row">
                            <div className="splash-screen-city-container_wrapper col-md-12 col-lg-4">
                                <Link
                                    to={`/${CityType.Kolarovo}/menu`}
                                    className="splash-screen-city kolarovo "
                                >
                                    {t('Kolarovo')}
                                </Link>
                            </div>
                            <div className="splash-screen-city-container_wrapper col-md-12 col-lg-4">
                                <Link
                                    to={`/${CityType.NoveZamky}/menu`}
                                    className="splash-screen-city nove-zamky"
                                >
                                    {t('NoveZamky')}
                                </Link>
                            </div>
                            <div className="splash-screen-city-container_wrapper col-md-12 col-lg-4">
                                <Link
                                    to={`/${CityType.Komarno}/menu`}
                                    className="splash-screen-city komarno"
                                >
                                    {t('Komarno')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6"></div>
        </div>
        <CookieConsent />
    </div>
);
