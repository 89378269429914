import React from 'react';
import { t } from 'i18next';
import { CityType } from '../../../types/cityType';
import { getAddress, getMapsSource, getOpeningHours } from './utils';
import './Contacts.scss';

type ContactsParameters = {
    city: CityType;
};

export const Contacts = (params: ContactsParameters) => {
    const address = getAddress(params.city);
    const openingHours = getOpeningHours(params.city);

    return (
        <div
            id="Contacts"
            className="contacts-content text-md-center text-lg-end"
        >
            <div className="container">
                <h1>{t('KontaktujteNas')}</h1>
                <div className="contacts-details-container row">
                    <div className="col-md-12 col-lg-6 p-0">
                        <div className="address">
                            {address.street} {address.city}
                        </div>
                        <div className="tel">{address.tel}</div>
                    </div>
                    <div className="col-md-12 col-lg-6 p-0">
                        <div className="fw-bold">{t('OtvaraciaDoba')}</div>
                        <div>
                            {t('Po_So')}: {openingHours.FromMondayToSaturday}
                        </div>
                        <div>
                            {t('Ne')}: {openingHours.Sunday}
                        </div>
                    </div>
                </div>
                <div className="map-container">
                    <iframe
                        title="aa"
                        src={getMapsSource(params.city)}
                        width="100%"
                        height="500"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};
