import React from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { CityType } from '../../../types/cityType';
import { getPhoneNumber } from '../../../shared/utils/phoneNumberUtils';
import bannerLogo from '../../../images/splash-header.png';
import { LocationPicker } from './LocationPicker/LocationPicker';
import { LanguagePicker } from '../LanguagePicker/LanguagePicker';
import './Header.scss';

interface HeaderProp {
    city: CityType;
}

export const Header = (prop: HeaderProp) => {
    const phoneNumber = getPhoneNumber(prop.city);

    return (
        <header className="header">
            <div className="container header__container">
                <div className="row">
                    <div className="header__banner col-md-6 col-lg-4 justify-content-md-start justify-content-lg-start justify-content-xl-start">
                        <Link key="header-banner-logo" to="/">
                            <img
                                alt="logo"
                                src={bannerLogo}
                                className="header-logo"
                            />
                        </Link>
                    </div>
                    <div className="header__contacts d-none d-lg-flex col-lg-4">
                        <span className="phone__text">
                            {t('PultovyPredajARozvorPizze')}
                        </span>
                        <span className="phone_number">{phoneNumber}</span>
                    </div>
                    <div className="header__events col-md-6 col-lg-4 justify-content-md-end justify-content-lg-end justify-content-xl-end">
                        <div className="events_location">
                            <LocationPicker city={prop.city} />
                        </div>
                        <div className="events__language">
                            <LanguagePicker />
                        </div>
                    </div>
                    <div className="header__contacts d-block text-center d-lg-none col-12">
                        <div className="phone__text">
                            {t('PultovyPredajARozvorPizze')}
                        </div>
                        <div className="phone_number">{phoneNumber}</div>
                    </div>
                </div>
            </div>
        </header>
    );
};
