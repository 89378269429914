import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Food } from '../types/Food';
import { getFoods } from '../foodUtils';
import { CityType } from '../../../types/cityType';
import { getCurrentLanguage } from '../../../shared/providers/languageProvider';
import { HU } from '../../../constants/languages';
import './FoodList.scss';

type FoodListParameters = {
    city: CityType;
};

export const FoodList = (params: FoodListParameters) => {
    const [foods, setFoods] = useState<Food[] | null>(null);
    const currentLanguage = getCurrentLanguage();

    useEffect(() => {
        if (foods === null) {
            getFoods(params.city).then((e) => setFoods(e));
        }
    }, [params.city, foods]);

    const getThumbnailImageUri = (imageUri: string): string => {
        const tmp = imageUri.split('.');
        const seed = new Date().getTime();
        return `/${tmp[0]}-thumbnail.${tmp[1]}?v=${seed}`;
    };

    const getPriceDisplayValue = (price: number): string =>
        ` ${parseFloat(`${price}`).toFixed(2)}`;

    const getDescription = (f: Food): string =>
        currentLanguage.id === HU
            ? f.descriptionInHungarian
            : f.descriptionInSlovak;

    const resolveUri = (imageUri: string): string => `${window.location.protocol}//${window.location.host}/${imageUri}`;

    return (
        <div id="FoodList" className="food-list-wrapper">
            <div className="container p-0">
                <div className="food-list-container">
                    {foods?.map((f: Food, index: number) => (
                        <div
                            className="food-card"
                            key={`food-${f.queueNumber}-${index}`}
                        >
                            <div className="food-image-container">
                                <a href={resolveUri(f.imageUri)}>
                                    <img
                                        alt="pizza1"
                                        src={getThumbnailImageUri(f.imageUri)}
                                    />
                                </a>
                            </div>
                            <div className="food-title">#{f.queueNumber}</div>
                            <div className="food-ingredients">
                                {getDescription(f)}
                            </div>
                            <div className="price">
                                {getPriceDisplayValue(f.price)}€
                            </div>
                            <div className="price-with-delivery">
                                {t('PizzaNaDonasku')}:{' '}
                                <span>
                                    {getPriceDisplayValue(f.priceWithDelivery)}€
                                </span>
                            </div>
                            <div className="food-description">
                                <div className="food-size">
                                    <span className="material-symbols-outlined">
                                        width{' '}
                                    </span>
                                    {f.size} cm
                                </div>
                                <div className="food-weight">
                                    <span className="material-symbols-outlined">
                                        weight{' '}
                                    </span>
                                    min. {f.weight}g
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
