import React from 'react';
import huFlag from '../../../images/hu.png';
import svkFlag from '../../../images/sk.png';
import {
    getCurrentLanguage,
    getLanguage,
    setLanguage,
} from '../../../shared/providers/languageProvider';
import { HU, SK } from '../../../constants/languages';
import './LanguagePicker.scss';
import { changeLanguage } from 'i18next';

interface LanguagePickerProp {}

export const LanguagePicker = (prop: LanguagePickerProp) => {
    const currentLanguage = getCurrentLanguage();
    const resolveFlag = () => (currentLanguage.id === HU ? huFlag : svkFlag);
    const getUnusedLanguage = () =>
        currentLanguage.id === HU ? getLanguage(SK) : getLanguage(HU);
    const resolveUnusedLanguageFlag = () =>
        currentLanguage.id === HU ? svkFlag : huFlag;

    const switchLanguage = () => {
        const lng = getUnusedLanguage().id;

        setLanguage(lng);
        changeLanguage(lng);

        window.location.reload();
    };

    return (
        <div className="language-picker dropdown dropdown-flat">
            {currentLanguage && (
                <div>
                    <a
                        className="btn dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <img alt="svk" src={resolveFlag()} />
                        <span className="language__text">
                            {currentLanguage.translate}
                        </span>
                    </a>

                    <ul className="dropdown-menu">
                        <li>
                            <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => switchLanguage()}
                            >
                                <img
                                    alt="svk"
                                    src={resolveUnusedLanguageFlag()}
                                />
                                <span className="language__text">
                                    {getUnusedLanguage().translate}
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};
