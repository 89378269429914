export const transaltionResources = {
    sk: {
        translation: {
            Kolarovo: 'Kolárovo',
            NoveZamky: 'Nové Zámky',
            Komarno: 'Komárno',
            Preparing: 'pripravujeme...',
            PultovyPredajARozvorPizze: 'Pultový predaj a rozvoz pizze',
            Slovak: 'Slovenský',
            Hungarian: 'Magyar',
            Ponuka: 'Ponuka',
            Galeria: 'Galéria',
            Kontakty: 'Kontakty',
            Motto: 'Ak ste boli spokojný, povedzte to všetkým!',
            Motto2: 'Ak ste boli nespokojný, povedzte to nám...',
            OtvaraciaDoba: 'Otváracia doba',
            Po_So: 'Po. - So.',
            Ne: 'Ne.',
            KontaktujteNas: 'Kontaktujte nás',
            PizzaNaDonasku: 'Pizza na donášku',
            Zatvorene: 'zatvorené',
            CookieConsentHeader: 'Nastavenie ukladania cookies',
            CookieConsentDescription: 'Táto webstránka používa súbory cookies na prispôsobenie obsahu, poskytovanie funkcií sociálnych médií a na analýzu návštevnosti. Informácie o tom, ako používate naše webové stránky, poskytujeme aj našim partnerom v oblasti sociálnych médií, inzercie a analýzy. Títo partneri môžu príslušné informácie skombinovať s ďalšími údajmi, ktoré ste im poskytli alebo ktoré od vás získali, keď ste používali ich služby.',
            AcceptAll: "Prijať všetko",
            Options: "Možnosti",
            Basic: "Základné",
            BasicDescription: "Tieto položky sú potrebné na povolenie základných funkcií webových stránok.",
            Analytics: "Analytika",
            AnalyticsDescription: "Tieto položky pomáhajú prevádzkovateľovi webových stránok pochopiť výkonnosť jeho webových stránok, interakciu návštevníkov so stránkou a prípadné technické problémy. Tento typ úložiska zvyčajne nezhromažďuje informácie, ktoré identifikujú návštevníka.",
            Close: "Zatvoriť",
            Save: "Uložiť",
            CookieConsentDetailedDescription: "Cookies sú malé textové súbory, ktoré môžu byť použité webovými stránkami, aby zefektívnili užívateľovu skúsenosť.\n" +
                "Zákon hovorí, že môžeme ukladať súbory cookie na vašom zariadení, ak sú nevyhnutné pre prevádzku týchto stránok. Pri všetkých ostatných typoch súborov cookie potrebujeme váš súhlas.\n" +
                "Táto stránka používa rôzne typy cookies. Niektoré cookies sú tu umiestnené službami tretích strán, ktoré sa objavujú na našich stránkach. Svoj súhlas so Zásadami cookies môžete kedykoľvek zmeniť alebo odvolať na našej webovej stránke.",
            DataSecurity: "Ochrana osobných údajov"
        }
    },
    hu: {
        translation: {
            Kolarovo: 'Gúta',
            NoveZamky: 'Érsekújvár',
            Komarno: 'Komárom',
            Preparing: 'készítjük...',
            PultovyPredajARozvorPizze: 'Pulti eladás és kiszállítás',
            Slovak: 'Slovenský',
            Hungarian: 'Magyar',
            Ponuka: 'Ajánlat',
            Galeria: 'Galéria',
            Kontakty: 'Kapcsolat',
            Motto: 'Ha elégedett volt, mondja el mindekinek!',
            Motto2: 'Ha csalódott, mondja el nekünk...',
            OtvaraciaDoba: 'Nyitvatartás',
            Po_So: 'H. - Sz.',
            Ne: 'V.',
            KontaktujteNas: 'Üzenjen nekünk',
            PizzaNaDonasku: 'Pizza kiszállítással',
            Zatvorene: 'zárva',
            CookieConsentHeader: 'Cookie-tárolási beállítások',
            CookieConsentDescription: 'Ez a weboldal cookie-kat használ a tartalom testreszabására, a közösségi média funkciók biztosítására és a forgalom elemzésére. Ezenkívül tájékoztatást adunk közösségi médiás, hirdetési és elemzési partnereinknek arról, hogyan használja weboldalunkat. Ezek a partnerek egyesíthetik a releváns információkat más adatokkal, amelyeket Ön a szolgáltatásaik igénybevételekor megadott nekik, vagy kapott Öntől.',
            AcceptAll: "Mindent elfogadni",
            Options: "Lehetőségek",
            Basic: "Általános",
            BasicDescription: "Ezek az elemek szükségesek a webhely alapvető funkcióinak működéséhez.",
            Analytics: "Analitika",
            AnalyticsDescription: "Ezek az elemek segítenek a webhely üzemeltetőjének megérteni webhelye teljesítményét, a látogatók interakcióját az oldallal és az esetleges technikai problémákat. Az ilyen típusú tárolás általában nem gyűjt olyan információkat, amelyek azonosítják a látogatót.",
            Close: "Bezárás",
            Save: "Mentés",
            CookieConsentDetailedDescription: "A sütik kis szöveges fájlok, amelyeket a webhelyek a felhasználói élmény javítására használhatnak.\n" +
                "A törvény kimondja, hogy cookie-kat tárolhatunk az Ön eszközén, ha ezek az oldalak működéséhez szükségesek. Minden más típusú sütihez az Ön hozzájárulására van szükségünk.\n" +
                "Ez az oldal különböző típusú sütiket használ. Néhány cookie-t az oldalainkon megjelenő harmadik fél szolgáltatásai helyeznek el ide. Weboldalunkon bármikor megváltoztathatja vagy visszavonhatja a Cookie-szabályzathoz való hozzájárulását.",
            DataSecurity: "Adatvédelem"
        }
    }
};