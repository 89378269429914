import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CityType } from '../../../../types/cityType';
import { getLocation } from '../../../../shared/providers/locationProvider';
import { NavigationLinkType } from '../../../../types/navigationLink';
import { RestaurantLocation } from '../../../../types/restaurantLocation';
import './LocationPicker.scss';

interface LocationPickerProp {
    city: CityType;
}

export const LocationPicker = (prop: LocationPickerProp) => {
    const navigate = useNavigate();
    const currentLocation = getLocation(prop.city);
    const getUnusedLocations = (): RestaurantLocation[] => {
        switch (currentLocation.id) {
            case CityType.Kolarovo: {
                return [
                    getLocation(CityType.NoveZamky),
                    getLocation(CityType.Komarno)
                ];
            }
            case CityType.Komarno: {
                return [
                    getLocation(CityType.NoveZamky),
                    getLocation(CityType.Kolarovo)
                ];
            }
            case CityType.NoveZamky: {
                return [
                    getLocation(CityType.Komarno),
                    getLocation(CityType.Kolarovo)
                ];
            }
        }
    };

    const switchLocation = (locationId: CityType) => {
        const route = `/${locationId}${NavigationLinkType.Menu}`;
        navigate(route);

        window.location.reload();
    };

    return (
        <div className="location-picker dropdown dropdown-flat">
            {currentLocation && (
                <div>
                    <a
                        className="btn dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <span className="material-symbols-outlined">
                            pin_drop
                        </span>
                        <span className="location__text">
                            {currentLocation.translate}
                        </span>
                    </a>

                    <ul className="dropdown-menu">
                        {getUnusedLocations().map((item: RestaurantLocation, index: number) => {
                            return (<li key={index}>
                                <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => switchLocation(item.id)}
                                >
                                    <span className="language__text">
                                        {item.translate}
                                    </span>
                                </button>
                            </li>);
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};
