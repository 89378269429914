import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { t } from 'i18next';
import './CookieConsent.scss';
import { getCookieConsent, saveCookieConsent } from '../../../shared/providers/cookieConsentProvider';
import { CookieSetting } from '../../../types/cookieSetting';

interface CookieConsentProp {
}

export const CookieConsent = (prop: CookieConsentProp) => {
    const cookieSetting = getCookieConsent();
    const isCookieAlreadySaved = cookieSetting !== CookieSetting.NotDefined;
    const [isDialogOpen, setDialogIsOpen] = useState<boolean>(false);
    const [areAnalyticalCookiesAllowed, setAreAnalyticalCookiesAllowed] = useState<boolean>(cookieSetting !== CookieSetting.Rejected);

    const openDialog = (): void => setDialogIsOpen(true);

    const closeDialog = (): void => setDialogIsOpen(false);

    const onAnalyticalCookiesChanged = (): void => {
        const newValue = !areAnalyticalCookiesAllowed;

        setAreAnalyticalCookiesAllowed(newValue);
    };

    const onSaveClicked = (): void => {
        saveCookieConsent(areAnalyticalCookiesAllowed);
        closeDialog();

        window.location.reload();
    };

    return (
        <>
            {cookieSetting === CookieSetting.NotDefined && (<div className="cookie-consent-container">
                <h1>{t('CookieConsentHeader')}</h1>
                <p>{t('CookieConsentDescription')}</p>
                <div className="button-container">
                    <button className="btn-default btn-accept-all"
                            onClick={() => onSaveClicked()}>{t('AcceptAll')}</button>
                    <button className="btn-default btn-options"
                            onClick={() => openDialog()}>{t('Options')}</button>
                </div>
            </div>)}
            {isCookieAlreadySaved && (
                <div className="cookie-consent-settings-container" onClick={() => openDialog()}>
                    <span className="material-symbols-outlined cookie-icon">cookie</span>
                    <span>{t('DataSecurity')}</span>
                </div>
            )}
            <Modal
                show={isDialogOpen}
                onHide={() => closeDialog()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="cookie-consent-modal"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('CookieConsentHeader')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="detailed_description">{t('CookieConsentDetailedDescription')}</div>
                    <div className="option-container">
                        <div>{t('Basic')}</div>
                        <div className="switch-container">
                            {t('BasicDescription')}
                            <Form.Switch disabled checked></Form.Switch>
                        </div>
                    </div>
                    <div className="option-container">
                        <div>{t('Analytics')}</div>
                        <div className="switch-container">
                            {t('AnalyticsDescription')}
                            <Form.Switch
                                checked={areAnalyticalCookiesAllowed}
                                onChange={e => onAnalyticalCookiesChanged()}></Form.Switch>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn-default btn-save"
                            onClick={() => onSaveClicked()}>{t('Save')}</button>
                    <button className="btn-default" onClick={() => closeDialog()}>{t('Close')}</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
